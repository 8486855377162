import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import UserList from "./pages/users/UserList";
import UserNew from "./pages/users/UserNew";
import UserEdit from "./pages/users/UserEdit";
import UserPermissions from "./pages/users/UserPermissions";
import StudentUpload from "./pages/students/StudentUpload";
import StudentEdit from "./pages/students/StudentEdit";
import StudentNew from "./pages/students/StudentNew";
import StudentList from "./pages/students/StudentList";
import StudentEnableConcepts from "./pages/students/StudentEnableConcepts";
import Login from "./pages/auth/Login";
import ConfirmPassword from "./pages/auth/ConfirmPassword";
import './App.scss';
import ProtectedRoute from './services/ProtectedRoute';
import AcademicConceptsList from './pages/concepts/AcademicConceptsList';
import AcademicConceptNew from './pages/concepts/AcademicConceptNew';
import AcademicConceptEdit from './pages/concepts/AcademicConceptEdit';
import ConceptsUpload from './pages/concepts/ConceptsUpload';
import PackagesList from './pages/packages/PackagesList';
import PackagesNew from './pages/packages/PackagesNew';
import PackagesEdit from './pages/packages/PackagesEdit';
import PackagesAssign from './pages/packages/PackagesAssign';
import PackagesAssignedList from './pages/packages/PackagesAssignedList';
import PackagesTransfer from './pages/packages/PackagesTransfer';
import CyclesNew from './pages/cycles/CyclesNew';
import CyclesEdit from './pages/cycles/CyclesEdit';
import CyclesList from './pages/cycles/CyclesList';
import AssignedConcepts from './pages/concepts/ConceptsAssigned';
import ImportPayments from './pages/payments/ImportPayments';
import CashPayments from './pages/payments/CashPayments';
import PaymentsCreate from './pages/payments/CreatePayments';
import ConceptPaymentsUpload from './pages/payments/ConceptPaymentsUpload';
import PaymentsUpload from './pages/payments/PaymentsUpload';
import StudentCreateConcept from './pages/students/StudentCreateConcept';
import ConceptsReport from './pages/reports/ConceptsReport';
import ScholarshipList from './pages/scholarships/ScholarshipList';
import ScholarshipNew from './pages/scholarships/ScholarshipNew';
import ScholarshipEdit from './pages/scholarships/ScholarshipEdit';
import ScholarshipAssigned from './pages/scholarships/ScholarshipAssigned';
import ScholarshipToCancel from './pages/scholarships/ScholarshipToCancel';
import ScholarshipToDelete from './pages/scholarships/ScholarshipToDelete';
import ApplicantUpload from './pages/applicants/ApplicantUpload';
import ApplicantList from './pages/applicants/ApplicantList';
import ApplicantNew from './pages/applicants/ApplicantNew';
import ApplicantEdit from './pages/applicants/ApplicantEdit';
import { getConfig } from './services/Config.service';
import { getSession } from './services/Session.service';
import ConfigContext, { initialConfig } from './context/Config';
import SessionContext, { initialSession } from './context/Session';

function App() {

  const [config, setConfig] = useState({ loading: true, data: initialConfig });
  const [session, setSession] = useState({ loading: true, data: initialSession });

  const { loading, data } = config;

  useEffect(() => {
    async function getConfigAsync(){
      const { data } = await getConfig();
      setConfig({ loading: false, data });
    }
    getConfigAsync();
    async function getSessionAsync(){
      const { data } = await getSession();
      if(data.permissions){
        setSession({ loading: false, data });
      }
    }
    getSessionAsync();
  }
  , []);
  

  return (
    <div className="App">
      {
        loading && <p>Cargando aplicación</p>
      }
      {
        data.error && <p>'Error getting config from server'</p>
      }
      <ConfigContext.Provider value={data}>
        <SessionContext.Provider value={session.data}>
          <Header></Header>
          <Container fluid className="p-0 mt-5">
            <Router>
              <Switch>
                <ProtectedRoute path="/users/edit/:id" setRole={[1]}>
                  <UserEdit></UserEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/users/permissions/:id" setRole={[1]}>
                  <UserPermissions></UserPermissions>
                </ProtectedRoute>
                <ProtectedRoute path="/users/new" setRole={[1]}>
                  <UserNew></UserNew>
                </ProtectedRoute>
                <ProtectedRoute path="/users" setRole={[1]}>
                  <UserList></UserList>
                </ProtectedRoute>
                <ProtectedRoute path="/students/set/:id" setRole={[1]}>
                  <StudentCreateConcept></StudentCreateConcept>
                </ProtectedRoute>
                <ProtectedRoute path="/students/edit/:id" setRole={[1]}>
                  <StudentEdit></StudentEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/students/new" setRole={[1]}>
                  <StudentNew></StudentNew>
                </ProtectedRoute>
                <ProtectedRoute path="/students/upload" setRole={[1]}>
                  <StudentUpload></StudentUpload>
                </ProtectedRoute>
                <ProtectedRoute path="/students/enable" setRole={[1]}>
                  <StudentEnableConcepts></StudentEnableConcepts>
                </ProtectedRoute>
                <ProtectedRoute path="/students" setRole={[1]}>
                  <StudentList></StudentList>
                </ProtectedRoute>
                <ProtectedRoute path="/applicants/upload" setRole={[1]}>
                  <ApplicantUpload></ApplicantUpload>
                </ProtectedRoute>
                <ProtectedRoute path="/applicants/new" setRole={[1]}>
                  <ApplicantNew></ApplicantNew>
                </ProtectedRoute>
                <ProtectedRoute path="/applicants/edit/:id" setRole={[1]}>
                  <ApplicantEdit></ApplicantEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/applicants" setRole={[1]}>
                  <ApplicantList></ApplicantList>
                </ProtectedRoute>
                <ProtectedRoute path="/reports" setRole={[1]}>
                  <ConceptsReport></ConceptsReport>
                </ProtectedRoute>
                <ProtectedRoute path="/concepts/edit/:id" setRole={[1]}>
                  <AcademicConceptEdit></AcademicConceptEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/concepts/upload" setRole={[1]}>
                  <ConceptsUpload></ConceptsUpload>
                </ProtectedRoute>
                <ProtectedRoute path="/concepts/new" setRole={[1]}>
                  <AcademicConceptNew></AcademicConceptNew>
                </ProtectedRoute>
                <ProtectedRoute exact path="/concepts" setRole={[1]}>
                  <AcademicConceptsList></AcademicConceptsList>
                </ProtectedRoute>
                <ProtectedRoute path="/packages/edit/:id" setRole={[1]}>
                  <PackagesEdit></PackagesEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/packages/assign/:id" setRole={[1]}>
                  <PackagesAssign></PackagesAssign>
                </ProtectedRoute>
                <ProtectedRoute path="/packages/assigned-list/:id" setRole={[1]}>
                  <PackagesAssignedList></PackagesAssignedList>
                </ProtectedRoute>
                <ProtectedRoute path="/packages/new" setRole={[1]}>
                  <PackagesNew></PackagesNew>
                </ProtectedRoute>
                <ProtectedRoute path="/packages/transfer" setRole={[1]}>
                  <PackagesTransfer />
                </ProtectedRoute>
                <ProtectedRoute path="/packages" setRole={[1]}>
                  <PackagesList></PackagesList>
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships/assign/:id" setRole={[1]}>
                  <ScholarshipAssigned></ScholarshipAssigned>
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships/cancel/:id" setRole={[1]}>
                  <ScholarshipToCancel></ScholarshipToCancel>
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships/delete/:id" setRole={[1]}>
                  <ScholarshipToDelete />
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships/edit/:id" setRole={[1]}>
                  <ScholarshipEdit></ScholarshipEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships/new/:id" setRole={[1]}>
                  <ScholarshipNew></ScholarshipNew>
                </ProtectedRoute>
                <ProtectedRoute path="/scholarships" setRole={[1]}>
                  <ScholarshipList></ScholarshipList>
                </ProtectedRoute>
                <ProtectedRoute path="/payments/concept/upload" setRole={[1]}>
                  <ConceptPaymentsUpload></ConceptPaymentsUpload>
                </ProtectedRoute>
                <ProtectedRoute path="/payments/import" setRole={[1]}>
                  <ImportPayments></ImportPayments>
                </ProtectedRoute>
                <ProtectedRoute path="/payments/upload" setRole={[1]}>
                  <PaymentsUpload></PaymentsUpload>
                </ProtectedRoute>
                <ProtectedRoute path="/payments/create" setRole={[1]}>
                  <PaymentsCreate></PaymentsCreate>
                </ProtectedRoute>
                <ProtectedRoute path="/cycles/edit/:id" setRole={[1]}>
                  <CyclesEdit></CyclesEdit>
                </ProtectedRoute>
                <ProtectedRoute path="/cycles/new" setRole={[1]}>
                  <CyclesNew></CyclesNew>
                </ProtectedRoute>
                <ProtectedRoute path="/cycles" setRole={[1]}>
                  <CyclesList></CyclesList>
                </ProtectedRoute>
                <ProtectedRoute path="/assigned-concepts/:id" setRole={[1]}>
                  <AssignedConcepts></AssignedConcepts>
                </ProtectedRoute>
                <ProtectedRoute path="/cash-payments/:id" setRole={[1]}>
                  <CashPayments></CashPayments>
                </ProtectedRoute>
                <Route path="/auth/confirm/:token">
                  <ConfirmPassword></ConfirmPassword>
                </Route>
                <Route path="/login">
                  <Login></Login>
                </Route>
                <Route exact path="/">
                  <Login></Login>
                </Route>
              </Switch>
            </Router>
          </Container>
          <Footer></Footer>
        </SessionContext.Provider>
      </ConfigContext.Provider>
    </div>
  );
}

export default App;
